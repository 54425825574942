<template>
  <Accordion
    :pt="{ tab: '!p-1' }"
    :ptOptions="{ mergeProps: true }"
    v-model:activeIndex="activeTab"
  >
    <AccordionTab
      v-for="(tab, idx) in tabs"
      :key="idx"
      :header="tab.header"
      :ptOptions="{ mergeProps: true }"
      :pt="{
        root: 'border-b border-cool-gray-200 !p-0',
        header: 'border-b border-none hover:!bg-cement-200 !p-0'
      }"
    >
      <template #headericon>
        <icon v-if="activeTab === idx" :icon="['fas', 'minus']" class="mr-5" />
        <icon v-else :icon="['fas', 'plus']" class="mr-5" />
      </template>
      <template #default>
        <p class="m-3">{{ tab.text }}</p>
      </template>
    </AccordionTab>
  </Accordion>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'

const activeTab = ref(null)

defineProps({
  tabs: {
    type: Array,
    default: () => []
  }
})
</script>

<style lang="scss">
/* Remove the default border between the header and content */
.p-accordion-header {
  border-bottom: none !important;
  &:hover {
    background-color: red;
  }
}

/* Add the border below the content */
.p-accordion-content {
  border-bottom: 1px solid #ccc; /* Adjust the color and style as needed */
  padding-bottom: 1rem; /* Add some padding if desired */
}
</style>
